const hgmpMrMOrderForm = local_associations => {
  $(document).ready(function() {
    $("#success-alert").hide();
    $("#error-alert").hide();
  });

  //filter regional and local associations
  var $selectRegional = $('[name="hgmp_order[regional_association]"]');
  var $selectLocal = $('[name="hgmp_order[local_association_id]"]');
  $selectRegional.select2({
    placeholder: "Bitte den Landesverband auswählen oder suchen",
  });
  $("select[name='hgmp_order[local_association_id]']").select2({
    placeholder: "Bitte erst den Landesverband auswählen",
  });

  //filter local associations list for each regional association
  function select_options(selection) {
    var filteredRegionalAssociation = local_associations[selection];
    var options = filteredRegionalAssociation.map(option =>
      $("<option>", { value: option.id, text: option.name }),
    );
    return options;
  }

  $selectRegional.change(function() {
    var search = $selectRegional.val();
    var options = select_options(search);
    $selectLocal.empty().append(options);
    $selectLocal.select2("val", options[0].val());
  });

  //open and append modal with submit
  function previewBeforeSubmit() {
    var firstName = $('[name="hgmp_order[first_name]"]').val();
    var lastName = $('[name="hgmp_order[last_name]"]').val();
    var streetName = $('[name="hgmp_order[street_name]"]').val();
    var houseNumber = $('[name="hgmp_order[house_number]"]').val();
    var zipCode = $('[name="hgmp_order[zip_code]"]').val();
    var city = $('[name="hgmp_order[city]"]').val();
    var localAssociation = $(
      '[name="hgmp_order[local_association_id]"] option:selected',
    ).text();
    var regionalAssociation = $(
      '[name="hgmp_order[regional_association]"] option:selected',
    ).text();
    var gift = $('[name="hgmp_order[gift_id]"] option:selected').text();

    $("#custom-text").html("");
    $("#custom-text").append(`
      <div class="biggerFont">
      <h3>Bitte überprüfen Sie ihre Eingaben:</h3>
      <p><b>Landesverband:</b> ${
        _.isEmpty(regionalAssociation)
          ? "<font color='red'>Kein Landesverband ausgewählt.</font>"
          : regionalAssociation
      }</p>
      <p><b>Ortsverein:</b> ${
        _.isEmpty(localAssociation)
          ? "<font color='red'>Kein Ortsverein ausgewählt.</font>"
          : localAssociation
      }</p>
      <p><b>Vorname:</b> ${firstName}</p>
      <p><b>Nachname:</b> ${lastName}</p>
      <p><b>Straße und Hausnummer:</b> ${streetName} ${houseNumber}</p>
      <p><b>Postleitzahl:</b> ${zipCode}</p>
      <p><b>Stadt:</b> ${city}</p>
      <p><b>Prämie:</b> ${
        gift == "Bitte die Prämie auswählen"
          ? "<font color='red'>Keine Prämie ausgewählt.</font>"
          : gift
      }</p>
      </div> `);
  }
  //preview form before submitting the order
  $("#members-recruit-members-form").submit(e => {
    e.preventDefault();
    previewBeforeSubmit();
    $("#previewModal").modal("show");
  });

  $("#modalClose").click(function(e) {
    $("#submitButton").attr("disabled", false);
  });

  $("#ajaxSubmit").click(() => {
    var form = $("#members-recruit-members-form");
    $.ajax({
      type: form.attr("method"),
      url: form.attr("action"),
      data: form.serialize(),
      success: () => {
        $("#success-alert")
          .fadeTo(2000, 500)
          .slideUp(500, () => {
            $("#success-alert").slideUp(500);
            setTimeout(location.reload(true), 2000);
          });
      },
      error: () => {
        $("#error-alert")
          .fadeTo(2000, 500)
          .slideUp(1500);
      },
    });
  });
};

export default hgmpMrMOrderForm;
